import React from 'react'
import Link from 'next/link'
import {useRouter} from 'next/router'

import {useTextCopy} from '@invitae/nucleobase'

import {TextCopyType} from 'constants/textCopies'

import styles from './InvalidShareLink.module.scss'

const toCamelCase = (str: any) => {
  return str.replace(/_([a-z])/g, (_: any, letter: any) => letter.toUpperCase())
}

const InvalidShareLink = () => {
  // Retrieve the 'code' query parameter, defaulting to 'invalidLink' if not found
  // Ensure 'code' is always a string and convert it to camelCase
  const {
    query: {code},
  } = useRouter()
  const codeString = toCamelCase(Array.isArray(code) ? code.join(',') : code || 'invalidLink')

  // Use getText to get the text asset for this error code from the CMS
  const {getText, isKeywordEmpty} = useTextCopy<TextCopyType<'invalidShareLink'>>()

  return (
    <div className={styles.root}>
      <div className="nv-container">
        <div className="nv-body">
          <p>{isKeywordEmpty(codeString) ? getText('invalidLink') : getText(codeString)}</p>

          {codeString === 'employeeAlreadyUsed' && (
            <p className={styles.extraLink}>
              <Link href="/pio/claim">Go back to test claim page</Link>
            </p>
          )}

          {codeString === 'alreadyUsed' && (
            <p className={styles.extraLink}>
              <Link href="/pio/order">Continue to place an order</Link>
            </p>
          )}
        </div>
      </div>
    </div>
  )
}

export default InvalidShareLink

import * as React from 'react'

import {BrowserContext} from '@invitae/nucleobase'

// exit behavior on the quiz is one of two things
// 1. if the last page the user visited was an invitae one go back to that page
// 2. if the user came from outside send them to the homepage
export function useExitQuiz() {
  // last page visited by an internal site can be set with this context
  const {history} = React.useContext(BrowserContext) || {}

  let lastInternalPage: string | undefined

  const isServerSide = typeof window === 'undefined'
  if (!isServerSide) {
    if (history && !history.includes('quiz')) {
      // best case use history set internally
      lastInternalPage = history
    } else if (document.referrer && document.referrer.includes('invitae')) {
      // fall back to less reliable method
      lastInternalPage = document.referrer
    }
  }

  const exitQuiz = React.useCallback(() => {
    if (lastInternalPage) {
      window.location.href = lastInternalPage
    } else {
      window.history.back()
    }
  }, [lastInternalPage])

  return exitQuiz
}
